import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
// import LayoutInner from "common/components/Layout";
// import Titlebar from "common/components/Titlebar";
// import FAQToggle from "common/components/FAQs";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairBonding = () => {
  const seoData = {
    title: 'Womens Wigs for Cancer Patients Delhi -Radiance Hair Studio',
    description: '',
    keywords: ['womens wigs for cancer patients in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "mainEntityOfPage": "Service Page",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/womens-wigs-for-cancer-patient.jpg",
      "https://www.radiancehairstudio.com/womens-wigs-for-cancer-patients1.png",
      "https://www.radiancehairstudio.com/womens-wigs-for-cancer-patients2.png"
    ],
    "additionalType": "https://www.radiancehairstudio.com/womens-wigs-for-cancer-patients.php",
    "category": "Hair Wigs",
    "disambiguatingDescription": "Women's wigs for cancer patients in Delhi",
    "description": "Losing hair due to chemotherapy can lead to emotional stress. Transform your look with our 100% natural human hair wigs designed specifically for women undergoing chemotherapy.",
    "serviceType": "Women's Wig for Cancer Patients"
  };  
  // const faqs = [
  //   {
  //     title: "How do I care for my wig?",
  //     description:
  //       "While opting for a human hair wig that is natural the care becomes much easier. The wig feels like one's natural hair and can be maintained accordingly.",
  //   },
  //   {
  //     title: "Should I Buy More Than One Wig?",
  //     description:
  //       "While opting for a human hair wig that is natural the care becomes much easier. The wig feels like one's natural hair and can be maintained accordingly.",
  //   },
  //   {
  //     title: "How do I care for my wig?",
  //     description:
  //       "While opting for a human hair wig that is natural the care becomes much easier. The wig feels like one's natural hair and can be maintained accordingly.",
  //   },
  //   {
  //     title: "Will my hair still grow after adopting a hair weaving treatment?",
  //     description:
  //       "The hair weaving process is just extra hair shafts that are being braided or clipped to cover up baldness. Of course ones hair keeps growing as usual even after adopting the same.",
  //   },
  // ];
  const sectionS1 = [
    {
      title: "Customized Wigs for Women",
      img: "/custom-wigs.png",
    },
    {
      title: "Wig for Women’s",
      img: "/womens-wig.png",
    },
    {
      title: "Hair Replacement",
      img: "/hair-replacement.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/non.png",
    }
  ];

  const sectionS2 = [
    {
        img: "/service-images/womens-wigs-for-cancer-patient.jpg",
        content: (
            <div>
                <h2>Fight the CANCER With Ultimate Confidence!</h2>
                <p>Hair adds to the physical beauty of an individual, boosts our confidence and reflects your unique character. And no matter what comes in the way, CONFIDENCE should never be COMPROMISED upon! Wigs are thus the go-to solution while undergoing chemo.</p>
                <h2>Radiance Rocks!</h2>
                <p>Hair adds to the physical beauty of an individual, boosts our confidence and reflects your unique character. And no matter what comes in the way, CONFIDENCE should never be COMPROMISED upon! Wigs are thus the go-to solution while undergoing chemo.</p>
                <p>The first step in choosing the one for you would be chopping off the hair shortest. <a href="/appointment.php" style={{ color: "#373737"}}>Consulting</a> a hairdresser and acting accordingly comes next.</p>
                <p>At Radiance Hair Studio our hairdresser studies the preference of individual women. Measuring the hairlines, followed by studying the hair texture, length and color and finally the customization. EXACTLY! we provide our customers with the best possible wigs designed solely for them. Keeping in mind the cure for CANCER, all natural elements are used in the customization of the WIGs along with 100% natural human hair that RADIANCE provides!</p>
                <p>At RADIANCE we believe in empowering individuals. The free expert consultation we provide is a step set forward with the same AIM. Our work ethics makes us unique in every possible way. WIG customization for every woman is done here with utter excellence and special care. We await to accompany you on your battle; not BALD be BOLD!</p>
            </div>
        )
    }
];

const sectionS3 = [
    {
        smallImg: "/womens-wigs-for-cancer-patients1.png",
        largeImg: "/womens-wigs-for-cancer-patients2.png",
        content: (
            <div>
                <h2>Benefits of Wigs for Cancer Patient</h2>
                <p>Ever wondered why your hair needs that real HUMAN HAIR texture! If not, let me tell you, the authentic wigs may cost you cheaper but adding loads of fairness to your CHARACTER. The synthetic hair wigs visibly appears a lot more shiny and stiff. Which can neither feel like your natural mane, not can create an illusion visibly?</p>
                <p>So, HOLA TO THE HUMAN <a href="/about.php" style={{ color: "#373737"}}>HAIR!</a> Giving all the ladies the best they deserve. Perfectly reflecting the very own character. Here you go with the advantages.</p>
                <ul>
                    <li> Bidding BYE BYE to all the fakeness, the all natural human hair wigs gifts cancer-fighting women their very natural look. The texture doesn't feel stiff and does not add any extra unnecessary shine.</li>
                    <li> Wearing a human hair wig will let your style accordingly. Eliminating any kind of roughness or texture problem. Maintenance becomes easy and the hustle free. </li>
                    <li> Drives away all the worries of looking artificial. Feels soft, and this the natural color is maintained. </li>
                    <li> The natural human hair is a one-time investment. One perfect wig goes a long way.  </li>
                </ul>
                <p>All the worries those were chaining you down from adopting a natural human hair wig is now solved. Get urself one and fight that DEVIL with all your WILL!</p>
            </div>
        )
    }
];

const sectionS4 = [
    {
        title: "Womens wigs-for-cancer-patients FAQs",
        description: "Fighting the CANCER is already a big difference introduced to your life. Now opting for a wig may make you ask a lot of question. Here you go with that. Read you all question ANSWERED!",
        description2: "",
        faq: [
            {
                title: "How do I care for my wig?",
                description: "While opting for a human hair wig that is natural the care becomes much easier. The wig feels like one's natural hair and can be maintained accordingly."
            },
            {
                title: "Should I Buy More Than One Wig?",
                description: "While opting for a human hair wig that is natural the care becomes much easier. The wig feels like one's natural hair and can be maintained accordingly."
            },
            {
                title: "How do I care for my wig?",
                description: "While opting for a human hair wig that is natural the care becomes much easier. The wig feels like one's natural hair and can be maintained accordingly."
            },
            {
                title: "What Are Your Wig Shopping Tips?",
                description: "The shopping guide to your wig is all discussed above. At RADIANCE we offer a free consultation that will help you buy the best for urself."
            },
            {
                title: "What are the disadvantages of wearing a wig?",
                description: "Disadvantages! While fighting cancer! Maybe A WRONG QUESTION ASKED! Natural human hair wigs are blessings in disguise. All natural, no side effects, and longevity. What more do you want?"
            },
            {
                title: "Can I style or cut my wig short?",
                description: "Now styling is a concerning factor for ladies. Of course, the hair we wear reflects our personality. RADIANCE have your problem sorted. Our hairdresser provides and recommends all possible styling tips that reflect the personalized characters."
            },
            {
                title: "Can one use any product on their wigs?",
                description: "Now styling is a concerning factor for ladies. Of course, the hair we wear reflects our personality. RADIANCE have your problem sorted. Our hairdresser provides and recommends all possible styling tips that reflect the personalized characters"
            }
        ],

    }
];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Fight the CANCER With Ultimate Confidence!" banner="/wig-for-cancer-patients.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Fight the CANCER With Ultimate Confidence!" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairBonding;
